.header {
  box-shadow: 0 3px 10px rgba(172, 172, 172, 0.2);
  padding-top: 20px;
  padding-bottom: 10px;
  padding-left: 120px;
  padding-right: 120px;
}

.headerContent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  max-width: 1280px;
  margin: 0 auto;
}

.headerMobile {
  box-shadow: 0 3px 10px rgba(172, 172, 172, 0.2);
  text-align: center;
  font-size: 36px;
  padding-top: 20px;
  padding-bottom: 10px;
}

.subHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
}

.subHeaderMobile {
  display: flex;
  flex-direction: column;
}

.subHeaderAfterLogin {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.subHeaderAfterLoginMobile {
  display: flex;
  flex-direction: column;
}

.subscribe {
  outline: none;
  font-size: 14px;
  padding: 3px;
  border: none;
  background-color: white;
  cursor: pointer;
  font-weight: 600;
}

.subscribeMobile {
  cursor: pointer;
  padding: 20px;
  background-color: rgb(245, 245, 245);
  font-size: 14px;
  text-align: center;
  font-weight: 600;
}

.subscribeLoginMobile {
  cursor: pointer;
  padding: 20px;
  background-color: rgb(245, 245, 245);
  font-size: 14px;
  text-align: center;
}

.subscribeLoginMobile:hover {
  background-color: rgb(240, 240, 240);
}

.subscribe:hover {
  border-bottom: 1px solid black;
}

.subscribeMobile:hover {
  background-color: rgb(228, 228, 228);
}

.adminLogin {
  outline: none;
  font-weight: 700;
  font-size: 14px;
  padding: 3px;
  border: none;
  background-color: white;
  cursor: pointer;
}

.adminLoginMobile {
  outline: none;
  width: 280px;
  padding: 20px;
  font-weight: 700;
  font-size: 14px;
  border: none;
  background-color: rgb(234, 234, 234);
  cursor: pointer;
}

.adminLogin:hover {
  border-bottom: 1px solid black;
}

.adminLoginMobile:hover {
  background-color: rgb(228, 228, 228);
}

.avatarDropdown {
  position: relative;
}

.avatarDropdownMobile {
  padding: 10px;
  display: flex;
  font-weight: 600;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.avatar {
  padding-top: 1px;
  padding-right: 5px;
  padding-left: 5px;
  font-weight: 600;
  border-radius: 5px;
  cursor: pointer;
}

.avatarMobile {
  padding: 10px;
}

.dropdownOptions {
  border-radius: 5px;
  background-color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 200px;
  transform: translate(-50%, 15%);
  box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.2);
  padding: 10px 0px;
  border-top: 2px solid #e23325;
  z-index: 1;
}

.dropdownOptionsMobile {
  font-size: 14px;
  text-align: center;
  display: flex;
  flex-direction: column;
}

.task {
  outline: none;
  width: 180px;
  padding: 10px;
  font-size: 13px;
  border: none;
  background-color: rgb(245, 245, 245);
  cursor: pointer;
}

.taskMobile {
  outline: none;
  width: 280px;
  padding: 20px;
  font-size: 14px;
  border: none;
  background-color: rgb(234, 234, 234);
  cursor: pointer;
}

.task:hover {
  background-color: rgb(228, 228, 228);
}

.taskMobile:hover {
  background-color: rgb(228, 228, 228);
}

.logout {
  outline: none;
  padding: 10px;
  border: none;
  font-size: 13px;
  width: 180px;
  background-color: rgb(245, 245, 245);
  cursor: pointer;
}

.logoutMobile {
  outline: none;
  width: 280px;
  padding: 20px;
  font-size: 14px;
  border: none;
  background-color: rgb(245, 245, 245);
  cursor: pointer;
}

.logout:hover {
  background-color: rgb(228, 228, 228);
}

.logoutMobile:hover {
  background-color: rgb(228, 228, 228);
}

.navName {
  font-weight: 600;
  font-size: 14px;
}

.taskProgress {
  display: flex;
  width: 250px;
  background-color: #0052cc;
  flex-direction: row-reverse;
  color: white;
  align-items: center;
  padding-left: 14px;
  padding-right: 14px;
  padding-top: 7px;
  padding-bottom: 7px;
  border-radius: 5px;
  justify-content: left;
}

.taskProgressComplete {
  display: flex;
  width: 250px;
  background-color: #218234;
  flex-direction: row-reverse;
  color: white;
  align-items: center;
  padding-left: 14px;
  padding-right: 14px;
  padding-top: 7px;
  padding-bottom: 7px;
  border-radius: 5px;
  justify-content: left;
}

.loader {
  padding-top: 7px;
}

.taskText {
  font-size: 16px;
}

.footerMobile {
  color: black;
  font-size: 10px;
  padding-left: 65px;
  position: fixed;
  bottom: 20px;
}

@media screen and (width < 780px) {
  .header {
    padding-left: 30px;
    padding-right: 30px;
    align-items: center;
    font-size: 32px;
  }
}

@media screen and (width < 330px) {
  .header {
    font-size: 26px;
  }
}
