.banner {
  background-image: -webkit-linear-gradient(
    30deg,
    rgb(29, 29, 31) 60%,
    rgb(24, 24, 24) 50%
  );
  align-items: center;
  text-align: center;
  color: rgb(219, 219, 219);
  font-size: 14px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  line-height: 20px;
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
}

.close {
  border: 1px solid white;
  padding-left: 7px;
  padding-right: 7px;
  padding-top: 1px;
  padding-bottom: 1px;
  cursor: pointer;
  background-color: transparent;
  color: rgb(219, 219, 219);
}

.close:hover {
  background-color: rgb(53, 53, 53);
}
