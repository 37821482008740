.footer {
  background-color: rgb(244, 244, 244);
  border-top: 2px solid red;
  border-image: linear-gradient(90deg, orange 20.98%, red 98.75%) 1 stretch;
  font-family: "Montserrat", sans-serif;
  color: rgb(96, 96, 96);
}

.svgWrap {
  width: 92px;
  padding-right: 24px;
  cursor: pointer;
}

.msWrap {
  width: 400px;
  font-size: 13px;
  line-height: 18px;
  padding-right: 10px;
  color: black;
}

.logoWrap {
  display: flex;
  align-items: center;
  font-size: 14px;
  padding-top: 30px;
  padding-bottom: 14px;
}

.linkWrap {
  margin-bottom: 16px;
}

.desktopLinksContainer {
  display: flex;
  flex-direction: row;
  font-size: 13px;
}

.flexACEC {
  display: flex;
}

.flexCEC {
  display: flex;
  flex-direction: row;
}

.memberButton {
  padding: 10px;
  background-color: white;
  cursor: pointer;
  outline: none;
  border: 1px solid grey;
  border-radius: 20px;
  font-weight: bold;
  color: grey;
}

.privacyUse {
  display: flex;
  flex-direction: row;
  font-size: 13px;
}

.lastFooterChild {
  align-items: center;
  justify-content: space-between;
  display: flex;
  margin-top: 30px;
  font-size: 11px;
  flex-direction: row;
}

@media screen and (max-width: 600px) {
  .desktopLinksContainer {
    display: flex;
    flex-direction: column;
  }

  .msWrap {
    width: 250px;
    font-size: 13px;
    line-height: 18px;
    color: black;
  }

  .flexACEC {
    display: flex;
    flex-direction: row;
  }

  .flexCEC {
    display: flex;
    flex-direction: row;
  }

  .lastFooterChild {
    flex-direction: column-reverse;
    align-items: flex-start;
  }
}

@media screen and (max-width: 420px) {
  .desktopLinksContainer {
    display: flex;
    flex-direction: column;
  }

  .msWrap {
    width: 250px;
    font-size: 13px;
    line-height: 18px;
    color: black;
  }

  .flexACEC {
    display: flex;
    flex-direction: row;
  }

  .flexCEC {
    display: flex;
    flex-direction: column;
  }

  .lastFooterChild {
    flex-direction: column-reverse;
    align-items: flex-start;
  }
}

@media screen and (width > 2000px) {
  .footer {
    padding: 0px 0px 30px 22%;
  }

  .privacyUse {
    padding-right: 24%;
  }
}

@media screen and (width < 2000px) {
  .footer {
    padding: 0px 0px 30px 20%;
  }

  .privacyUse {
    padding-right: 20%;
  }
}

@media screen and (width < 1600px) {
  .footer {
    padding: 0px 0px 30px 11%;
  }

  .privacyUse {
    padding-right: 13%;
  }
}

@media screen and (width < 1400px) {
  .footer {
    padding: 0px 0px 30px 4%;
  }

  .privacyUse {
    padding-right: 4%;
  }
}
